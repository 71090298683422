
import { Vue, Component } from "vue-property-decorator";
import AppVersion from "../../models/app_version";
import mobileAppRoutes from "@/api/routes/mobile_app";

@Component
export default class VIOSInstallation extends Vue {
  private current_version: AppVersion | null = null;

  private async created() {
    this.$api
      .get(mobileAppRoutes.ios, { params: { os: "iOS" } })
      .then(res => (this.current_version = res.data))
      .catch(() => this.$router.push({ name: "unknown" }));
  }
}
